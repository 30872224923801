html,
body,
#root {
  height: 100%;
  overflow-x: clip;
  color: #252525;
}

body {
  color: #252525;
  background-color: #fafafb;
  font-family: 'Public Sans', sans-serif;
  overscroll-behavior: none;
}

.fw-light200 {
  font-weight: 200;
}

.login-form {
  .form-button-row {
    padding-top: 15px;
  }

  .error-message {
    padding-top: 2px;
    color: red;
    font-size: 12px;
  }
}

.header {
  color: var(--base-color-fg-default, #15161a);
  font-family: 'Public Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 30px */
}

.subtext {
  color: var(--base-color-fg-subtle, rgba(21, 22, 26, 0.7));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
